import React from 'react';
import PropTypes from 'prop-types';

const RowLR = ({left, right}) => {
    return (
        <section className={'section-wrap'}>
            <div className={`row`}>
                <div className="col-md-6">
                    {left}
                </div>
                <div className="col-md-6">
                    {right}
                </div>
            </div>
        </section>
    )
};
RowLR.propTypes = {
    title: PropTypes.string,
    left: PropTypes.node.isRequired,
    right: PropTypes.node.isRequired
};
export default RowLR;
