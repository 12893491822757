import React,{useState} from 'react';
import {Navbar} from 'react-bootstrap/Navbar';

const PriceDashboard = () => {
    const [updatePriceImg,setUpdatePriceImg] = useState(false);
    setTimeout(()=>setUpdatePriceImg(!updatePriceImg),300000);

    return (
        <div className={'price-wrapper'}>
            <div className={'row'}>
                <div className="col-lg-3  col-sm-6">
                    <img className={'rounded mx-auto d-block'}
                         src={'http://www.kitconet.com/images/live/s_gold.gif'}
                         alt={'asd'}/>
                </div>
                <div className="col-lg-3 col-sm-6 ">
                    <img className={'rounded mx-auto d-block'}
                         src={'http://www.kitconet.com/images/live/s_silv.gif'}
                         alt={'[Most Recent Quotes from www.kitco.com]'}/>
                </div>
                <div className="col-lg-3  col-sm-6">
                    <img
                        className={'rounded mx-auto d-block'}
                        src={'http://www.kitconet.com/images/live/s_plat.gif'}
                        alt={'asd'}/>
                </div>
                <div className="col-lg-3  col-sm-6 ">
                    <img className={'rounded mx-auto d-block'}
                         src={'http://www.kitconet.com/images/live/s_plad.gif'}
                         alt={'asd'}/>
                </div>
            </div>
            <div className={'row align-items-center'}>
                <div className="col">
                    <img className={'rounded mx-auto img-fluid web'}
                         src={'http://www.kitconet.com/images/quote_fixes.gif'}
                         alt={''}/>
                    <img className={'rounded mx-auto img-fluid mobile'}
                         src={'http://www.kitconet.com/images/quotes_special.gif'}
                         alt={''}/>
                </div>
            </div>
        </div>

    )
};
export default PriceDashboard;