import React,{useEffect, useState}from 'react';
import { SigFox } from "../map/MarkerClusterer";
import {useSelector} from  'react-redux';
import { animateScroll } from "react-scroll";
import {footerData} from '../../../state-menagment/selectors';


const Footer = () => {
    const {data:{contact,about},loading}=  useSelector((state) => footerData(state));
    const [scroll, setScroll] = useState(0);
    const [top, setTop] = useState(0);
    useEffect(() => {
        setTop(100);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    const scrollToTop = () => {
        animateScroll.scrollToTop();
    };
    const handleScroll = () => {
        setScroll(window.scrollY);
    };
  return !loading && (
      <footer className="site-footer">
          <div className="container">
              <div className="row">
                  <div className=" col-md-6">
                      <h6>{about.title}</h6>
                      <p className="text-justify">
                          {about.content}
                      </p>
                  </div>

                  <div className="col-md-6">
                      <a href={"https://www.google.ru/maps/dir//Nor+Nork+5th+Microdistrict,+Yerevan,+Armenia/@40.1821732,44.5639382,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x406abcb25669d767:0x2723cfb3e9933b4e!2m2!1d44.5721208!2d40.1821539"}
                         className={'btn location-map'}>
                          <i className="fa fa-map-marker" aria-hidden="true"/>
                          Navigation to our shop
                      </a>
                      <SigFox/>
                  </div>
              </div>
              <hr/>
          </div>
          <div className="container">
              <div className="row">
                  <div className=" col-sm-6 col-xs-12">
                      <div className={'phone-number'}>
                          <span>Phone number : </span> <span>{contact.phone}</span>
                      </div>
                      <address> <span>Addres : </span> {contact.address}</address>
                      <div><span>Email :</span> contact.email</div>
                  </div>

                  <div className="col-sm-6 col-xs-12">
                      <ul className="social-icons">
                          <li><a className="facebook" href="#"><i className="fa fa-facebook"/></a></li>
                          <li><a className="twitter" href="#"><i className="fa fa-twitter"/></a></li>
                          <li><a className="linkedin" href="#"><i className="fa fa-linkedin"/></a></li>
                      </ul>
                  </div>
              </div>
              <p className="copyright-text">Copyright &copy; 2021 All Rights Reserved by UtahGoldExchange.</p>
          </div>
          <button
              className={`scroll-top ${scroll > top ? "show" : ""}`}
              onClick={() => scrollToTop()}
          >
              <i className="fa fa-angle-double-up"/>
          </button>
      </footer>
  )
};


export default Footer ;
