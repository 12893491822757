const API_URL = `https://utahgoldexchange.com/admin/api.php?get=info`;

export const FETCH_FOOTER_PENDING = "FETCH_FOOTER_PENDING";
export const FETCH_FOOTER_DONE = "FETCH_FOOTER_DONE";
export const FETCH_FOOTER_REJECTED = "FETCH_FOOTER_REJECTED";


export const fetchFooter = () => dispatch => fetch(API_URL)
    .then(response => response.json())
    .then(res => {
        dispatch({
            type:FETCH_FOOTER_DONE,
            payload: res
        })
    })
    .catch(err => {
        dispatch({
            type: FETCH_FOOTER_REJECTED,
            payload: err,
            error: true
        })
    });

fetchFooter();


