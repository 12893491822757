import React from 'react';
import Navigation from '../navigation';

const Header = () => {
    return (
        <>
            <Navigation/>
        </>
    )
};
export default Header;
