import {FETCH_HOME_SUCCESS} from "../actions/homeActions";

const initState = {
    data: []
};

const homeReducer = (state = initState, action) => {
    if (action.type === FETCH_HOME_SUCCESS) {
        return {
            ...state,
            data: action.payload
        };
    }
    return state;
};

export default homeReducer;
