const initState = {
  currencySymbol: "$",
  currencyName: "USD",
  currencyRate: 1
};

const currencyReducer = (state = initState) => {
  return state;
};

export default currencyReducer;
