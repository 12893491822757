import {FETCH_WATCH_PRODUCTS_SUCCESS} from "../actions/watchProductAction";

const initState = {
    products: []
};

const watchProductReducer = (state = initState, action) => {
    if (action.type === FETCH_WATCH_PRODUCTS_SUCCESS) {
        return {
            ...state,
            products: action.payload
        };
    }
    return state;
};

export default watchProductReducer;
