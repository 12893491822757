import {FETCH_FOOTER_DONE ,FETCH_FOOTER_PENDING ,FETCH_FOOTER_REJECTED} from '../actions/footer';


export default  (state, action) => {
    if (state === undefined) {
        return {
            data: {},
            loading: true,
            error: null,
        }
    }
    switch (action.type) {
        case FETCH_FOOTER_PENDING:
            return {
                data: {},
                loading: true,
                error: null
            };
        case FETCH_FOOTER_DONE :
            return {
                data: action.payload,
                loading: false,
                error: null,
            };

        case FETCH_FOOTER_REJECTED :
            return {
                data: {},
                loading: false,
                error: action.payload
            };
        default :
            return state;
    }
};




