const React = require("react");
// const { GOOGLE_MAP_KEY } = require("../global/Constants");

const fetch = require("isomorphic-fetch");
const { compose, withProps, withHandlers } = require("recompose");
const {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} = require("react-google-maps");
const {
    MarkerClusterer
} = require("react-google-maps/lib/components/addons/MarkerClusterer");
 const GOOGLE_MAP_KEY = "AIzaSyDjlRPWd_XtKP1NMzNQnYJM7N_-0qBSdAs";
const MapWithAMarkerClusterer = compose(
    withProps({
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=" +
            GOOGLE_MAP_KEY +
            "&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withHandlers({
        onMarkerClustererClick: () => markerClusterer => {
            const clickedMarkers = markerClusterer.getMarkers();
        }
    }),
    withScriptjs,
    withGoogleMap
)(props => (
    <GoogleMap
        defaultZoom={14}
        defaultCenter={{ lat: -33.8594594, lng: 151.1927715 }}
    >
        <MarkerClusterer
            onClick={props.onMarkerClustererClick}
            averageCenter
            enableRetinaIcons
            gridSize={60}
        >
            {props.markers.length !== 0}
            {props.markers.map(marker => (
                <Marker
                    key={marker.timestamp}
                    position={{ lat: marker.latitude, lng: marker.longitude }}
                />
            ))}
        </MarkerClusterer>
    </GoogleMap>
));

export class SigFox extends React.PureComponent {
    componentWillMount() {
        this.setState({ markers: [] });
    }

    componentDidMount() {
        // const url = [
        //   // Length issue
        //   `https://gist.githubusercontent.com`,
        //   `/farrrr/dfda7dd7fccfec5474d3`,
        //   `/raw/758852bbc1979f6c4522ab4e92d1c92cba8fb0dc/data.json`
        // ].join("");

        // fetch(url)
        //   .then(res => res.json())
        //   .then(data => {
        //     this.setState({ markers: data.photos });
        //   });

        const terryTrace = [
            {
                timestamp: "2018-08-08T09:01:51.000Z",
                latitude: -33.8699392,
                longitude: 151.1934655
            },
            {
                timestamp: "2018-08-08T09:11:27.000Z",
                latitude: -33.845059899999995,
                longitude: 151.15034789999999
            },
            {
                timestamp: "2018-08-08T09:22:28.000Z",
                latitude: -33.8370756,
                longitude: 151.1225964
            },
            {
                timestamp: "2018-08-08T09:23:42.000Z",
                latitude: -33.8371115,
                longitude: 151.1225629
            },
            {
                timestamp: "2018-08-08T21:35:10.000Z",
                latitude: -33.8370672,
                longitude: 151.1225888
            },
            {
                timestamp: "2018-08-08T21:44:39.000Z",
                latitude: -33.8390462,
                longitude: 151.1456719
            },
            {
                timestamp: "2018-08-08T21:55:25.000Z",
                latitude: -33.8590897,
                longitude: 151.16255379999998
            }
        ];
        const angusTrace = [
            {
                timestamp: "2018-08-09T05:46:39.000+0000",
                latitude: -33.8674748,
                longitude: 151.1940789
            },
            {
                timestamp: "2018-08-09T06:06:49.000+0000",
                latitude: -33.8674748,
                longitude: 151.1940789
            },
            {
                timestamp: "2018-08-09T06:44:02.000+0000",
                latitude: -33.8674748,
                longitude: 151.1940789
            },
            {
                timestamp: "2018-08-09T06:57:26.000+0000",
                latitude: -33.863361,
                longitude: 151.1945159
            },
            {
                timestamp: "2018-08-09T07:11:43.000+0000",
                latitude: -33.863361,
                longitude: 151.1945159
            },
            {
                timestamp: "2018-08-09T07:13:11.000+0000",
                latitude: -33.863361,
                longitude: 151.1945159
            },
            {
                timestamp: "2018-08-09T07:40:15.000+0000",
                latitude: -33.8680048,
                longitude: 151.204838
            },
            {
                timestamp: "2018-08-09T07:48:18.000+0000",
                latitude: -33.8030707,
                longitude: 151.1957013
            },
            {
                timestamp: "2018-08-09T07:58:48.000+0000",
                latitude: -33.8030707,
                longitude: 151.1957013
            },
            {
                timestamp: "2018-08-09T08:09:37.000+0000",
                latitude: -33.8017767,
                longitude: 151.19499009999998
            },
            {
                timestamp: "2018-08-09T08:12:22.000+0000",
                latitude: -33.8019453,
                longitude: 151.1945651
            },
            {
                timestamp: "2018-08-09T08:38:06.000+0000",
                latitude: -33.801853099999995,
                longitude: 151.1948251
            },
            {
                timestamp: "2018-08-09T09:31:31.000+0000",
                latitude: -33.801674399999996,
                longitude: 151.1946496
            },
            {
                timestamp: "2018-08-09T09:41:47.000+0000",
                latitude: -33.8019513,
                longitude: 151.1940615
            },
            {
                timestamp: "2018-08-09T10:05:23.000+0000",
                latitude: -33.8098956,
                longitude: 151.2092803
            },
            {
                timestamp: "2018-08-09T10:11:42.000+0000",
                latitude: -33.8082981,
                longitude: 151.22057519999998
            },
            {
                timestamp: "2018-08-09T12:15:48.000+0000",
                latitude: -33.8083506,
                longitude: 151.2205923
            },
            {
                timestamp: "2018-08-10T00:26:40.000+0000",
                latitude: -33.821604799999996,
                longitude: 151.2105046
            },
            {
                timestamp: "2018-08-10T00:30:43.000+0000",
                latitude: -33.8216348,
                longitude: 151.21041359999998
            },
            {
                timestamp: "2018-08-10T00:58:25.000+0000",
                latitude: -33.8838887,
                longitude: 151.2020739
            },
            {
                timestamp: "2018-08-10T01:03:41.000+0000",
                latitude: -33.8886592,
                longitude: 151.1908154
            },
            {
                timestamp: "2018-08-10T02:38:03.000+0000",
                latitude: -33.8897626,
                longitude: 151.1902838
            },
            {
                timestamp: "2018-08-10T03:01:37.000+0000",
                latitude: -33.887926799999995,
                longitude: 151.1683837
            },
            {
                timestamp: "2018-08-10T03:08:23.000+0000",
                latitude: -33.8880972,
                longitude: 151.1621487
            },
            {
                timestamp: "2018-08-10T03:30:46.000+0000",
                latitude: -33.888117799999996,
                longitude: 151.166224
            },
            {
                timestamp: "2018-08-10T03:40:41.000+0000",
                latitude: -33.8864322,
                longitude: 151.1780082
            },
            {
                timestamp: "2018-08-10T03:50:20.000+0000",
                latitude: -33.872720199999996,
                longitude: 151.1934624
            },
            {
                timestamp: "2018-08-10T04:01:37.000+0000",
                latitude: -33.872720199999996,
                longitude: 151.1934624
            },
            {
                timestamp: "2018-08-10T04:11:47.000+0000",
                latitude: -33.872720199999996,
                longitude: 151.1934624
            }
        ];
        this.setState({ markers: angusTrace });
    }

    render() {
        return <MapWithAMarkerClusterer markers={this.state.markers} />;
    }
}
