import React, {useEffect} from 'react';
import Header from '../view/components/header';
import Footer from '../view/components/footer';
import {fetchProducts} from '../state-menagment/actions/productActions';
import {fetchWatchProducts} from '../state-menagment/actions/watchProductAction';
import {fetchDetails} from '../state-menagment/actions/detailsAction';
import {fetchFooter} from '../state-menagment/actions/footer';
import {useDispatch} from 'react-redux';
import {fetchHomeData} from '../state-menagment/actions/homeActions';

const LayoutDefault = ({children}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchHomeData());
        dispatch(fetchFooter());
        dispatch(fetchProducts());
        dispatch(fetchWatchProducts());
        dispatch(fetchDetails())
    }, [dispatch]);
    return (
        <div className={'container-fluid'}>
            <Header className="reveal-from-bottom"/>
            <main className="container .mt-2">
                {children}
            </main>
            <Footer/>
        </div>
    )
};

export default LayoutDefault;
