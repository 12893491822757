import React from 'react';
import {Link} from 'react-router-dom';

const CubSection = ({data: {title, image, shortDescription, fullDescription, cat, type}}) => {

    function truncate(str, maxlength) {
        return (str.length > maxlength) ?
            str.slice(0, maxlength - 1) + '…' : str;
    }

    return (
        <div className="cub">

            <div className={'image-wrapper'}>
                <img src={image} alt={''}/>
            </div>
            <div className={'cub-description'}>
                <h2 className={'title'}>{title}</h2>
                <p className={'cub-description'}> {truncate(shortDescription,200)} </p>
                {
                    <p className={'cub-description'}>{truncate(fullDescription, 70)}</p>
                }
                {
                    <Link to={cat !== 'shop' ? `full-description/${type}-${cat}` : `${type}-${cat}`}>
                        <button className={'btn btn-warning more-info-btn'}>
                            {cat === 'shop' ? 'go to shop page' : 'read more'}
                        </button>
                    </Link>
                }
            </div>
        </div>
    )
};
export default CubSection;