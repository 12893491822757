import {FETCH_DETAILS_SUCCESS} from "../actions/detailsAction";

const initState = {
    data: {}
};

const detailsReducer = (state = initState, action) => {
    if (action.type === FETCH_DETAILS_SUCCESS) {
        return {
            ...state,
            data: action.payload
        };
    }
    return state;
};

export default detailsReducer;
