const API_URL = `https://utahgoldexchange.com/admin/api.php?get=home`;
export const FETCH_HOME_SUCCESS = "FETCH_HOME_SUCCESS";

const fetchHomeSuccess = data => ({
    type: FETCH_HOME_SUCCESS,
    payload: data
});


// fetch productOption
export const fetchHomeData = () => dispatch => fetch(API_URL)
    .then(response => response.json())
    .then(res => {
        dispatch( fetchHomeSuccess(res))
    })
    .catch(err => {
        console.log("Error: ", err);
    });
fetchHomeData();