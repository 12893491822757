import jewelryProductReducer from "./jewelryProductReducer";
import watchProductReducer from "./watchProductReducer";
import currencyReducer from "./currencyReducer";
import homeReducer from './homeReducer';
import detailsReducer from './detailsReducer';
import footerReducer from './footerDataReducer';
import {combineReducers} from "redux";

const rootReducer = combineReducers({
    jewelryData: jewelryProductReducer,
    watchData: watchProductReducer,
    currencyData: currencyReducer,
    homedData: homeReducer,
    detailsData: detailsReducer,
    footerData: footerReducer
});

export default rootReducer;
