const API_URL = `https://utahgoldexchange.com/admin/api.php?get=products&type=1`;

export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

const fetchProductsSuccess = products => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products
});

// fetch productOption
export const fetchProductsMoc = products => {
    return dispatch => {
        dispatch(fetchProductsSuccess(products));
    };
};

// fetch productOption
export const fetchProducts = () => dispatch => fetch(API_URL)
        .then(response => response.json())
        .then(res => {
          dispatch( fetchProductsSuccess(res))
        })
        .catch(err => {
          console.log("Error: ", err);
        });