import React from 'react';
import {Provider} from 'react-redux';
import {ToastProvider} from 'react-toast-notifications';
import {BrowserRouter as Router} from 'react-router-dom';
import LayoutDefault from './layout';
import routes from './routes';
import {applyMiddleware, createStore} from 'redux';
import rootReducer from './state-menagment/reducers';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import "./assets/scss/style.scss";

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
);

function App() {
    return (
        <Provider store={store}>
            <ToastProvider placement="bottom-left">
                <Router>
                    <LayoutDefault>
                        {routes}
                    </LayoutDefault>
                </Router>
            </ToastProvider>
        </Provider>
    );
}

export default App;
