const API_URL = `https://utahgoldexchange.com/admin/api.php?get=product-details`;
export const FETCH_DETAILS_SUCCESS = "FETCH_DETAILS_SUCCESS";


const fetchDetailsSuccess = data => ({
    type: FETCH_DETAILS_SUCCESS,
    payload: data
});


// fetch productOption
export const fetchDetails = () => dispatch => fetch(API_URL)
    .then(response => response.json())
    .then(res => {
        dispatch( fetchDetailsSuccess(res))
    })
    .catch(err => {
        console.log("Error: ", err);
    });
fetchDetails();