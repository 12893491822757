import React from 'react';
import { Navbar, Nav } from "react-bootstrap";
import { withRouter, Link} from "react-router-dom";

const Navigation = ({location}) => {
    return (
        <Navbar  bg="light"  variant="light" expand="lg"  fixed="top">
            <Navbar.Brand  className="App-logo">
                <Link to={'/'}>Home</Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto" activeKey={location.pathname}>
                    <Nav.Link >
                        <Link to={'/jewelry-shop'}>Jewelry Shop</Link>
                    </Nav.Link>
                    <Nav.Link>
                        <Link to={'/watch-shop'}>Watch Shop</Link>
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
};
export default withRouter( Navigation);