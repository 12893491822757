import React, {useLayoutEffect, useState} from 'react';
import { useSelector} from 'react-redux';
import Row from '../../components/RowLR';
import CubSection from '../../components/sections';
import {homedData} from '../../../state-menagment/selectors'
import PriceDashboard from '../../components/dashboardPrice';
const elementLoader =<div className="flone-preloader-wrapper">
    <div className="flone-preloader">
        <span/>
        <span/>
    </div>
</div>;
const HomePage = () => {
   useLayoutEffect(()=> {
       setTimeout(() =>
           setIsLoading(false), 1000)

   },[]);
   let [isLoading,setIsLoading]= useState(true);
    const homeData = useSelector(state => homedData(state));

    return homeData.length  && isLoading ?elementLoader : (
        <>
            <PriceDashboard/>
            {homeData.map((data, index) => {
                return (
                    <Row
                        left={<CubSection data={data[0]}/>}
                        right={<CubSection data={data[1]}/>}
                        key={index}
                    />
                )
            })
            }
        </>
    )
};
export default HomePage;