import React, {lazy,Suspense} from 'react';
import {Switch, Route,Redirect} from 'react-router-dom';
import HomePage from '../view/pages/home';
import ScrollToTop from "../helpers/scroll-top";
const Jewelry = lazy(() => import('../view/pages/jewelryShop'));
const WatchShop = lazy(() => import('../view/pages/watchShop'));
const FullDescription = lazy(() => import('../view/pages/full-description'));
const ProductOption = lazy(() => import("../view/pages/productOption/Product"));

export default (

    <ScrollToTop>
    <Switch>
        <Route path={'/'} component={HomePage} exact/>
        <Suspense
            fallback={
                <div className="flone-preloader-wrapper">
                    <div className="flone-preloader">
                        <span/>
                        <span/>
                    </div>
                </div>
            }
        >
            <Route path={'/jewelry-shop'} component={Jewelry} exact/>
            <Route path={'/watch-shop'} component={WatchShop} exact/>
            <Route
                path={"/product/:id"}
                exact
                render={routeProps => (
                    <ProductOption {...routeProps} key={routeProps.match.params.id} />
                )}
            />
            <Route path={'/full-description/:id'} component={FullDescription} exact/>
        </Suspense>
        <Route render={()=><h1>not found</h1>}/>
    </Switch>
    </ScrollToTop>
);
