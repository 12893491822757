const API_URL = `https://utahgoldexchange.com/admin/api.php?get=products&type=2`;

export const FETCH_WATCH_PRODUCTS_SUCCESS = "FETCH_WATCH_PRODUCTS_SUCCESS";

const fetchWatchProductsSuccess = products => ({
    type: FETCH_WATCH_PRODUCTS_SUCCESS,
    payload: products
});



// fetch productOption
export const fetchWatchProducts = () => dispatch => fetch(API_URL)
    .then(response => response.json())
    .then(res => {
        dispatch( fetchWatchProductsSuccess(res))
    })
    .catch(err => {
        console.log("Error: ", err);
    });

